<template>
  <main>
    <!-- <div class="first-body-section">
      <img
        id="fade"
        class="fade-in"
        src="@/assets/aboutpage/Instagram post - 8.png"
        alt
      />
      <img
        class="fade-in responsive-fade"
        src="@/assets/aboutpage/Instagram post - 8.png"
        alt
      />
    </div>
    <hr class="line" /> -->
    <div id="story-id" class="our-story-section">
      <span class="title">Our Story</span>
      <div class="rect-placeholder">
        <p>
          Matriclive as a concept, started in 2017. The founder & Chief
          Technology Officer Lesego Finger had just completed his matric in 2016
          and was waiting to start his diploma in software development.
          <br />
        </p>
        <p>
          During this idle time, he decided to use it constructively and create
          a platform that had all the resources he wished he had in Matric.
          <br />
        </p>
        <p>
          Upon the subsequent launch of the platform on the Google Playstore, it
          was well received by learners in a few local schools. The above led to
          its recognition by LeadSA, and as a result thereof Lesego was elected
          as a LeadSA hero during the year. <br />
        </p>
        <p>
          In 2019 Matriclive was recognized at the MTN Business App of the year
          awards for the best education solution of the year award. Through its
          consistent growth, a company (SAADC) was established under which
          Matriclive is the flagship product. <br />
        </p>
        <p>
          In 2020, Kagisho Masae, the co-founder & Chief Operating Officer
          joined the team to integrate the work and vision he had already
          initiated in the edu-tech space. <br />
        </p>
        <p>
          The platform experienced tectonic growth in 2020 attracting over 630
          000 learners over the Covid19 riddled academic year. This massive
          achievement was further validated by yet another award at the 2020 MTN
          Business App of the year awards, winning the Breakthrough Solution of
          the year category. <br />
        </p>
        <p>
          In 2023, Kagisho Masae, who initially joined as the co-founder & Chief Operating Officer, assumed the role of
          CEO, bringing his continued dedication and leadership to drive Matriclive's mission forward. <br />
        </p>
        <p>
          The platform has expanded to cover most of the highschool curriculum
          and the team continues to grow steadily. The aim through this platform
          amongst other initiatives that are in the pipeline from the holding
          company, is to decentralize quality education. <br />
        </p>
        <p>
          This is built on the premise and belief that every child deserves at
          the very least a shot at a quality education in their lifetime.
        </p>
      </div>
      <div>
        <div class="early-versions">
          <div class="early-versions-1">
            <div class="early-versions-1_1">
              <img class="slide-in from-left" src="@/assets/aboutpage/room.svg" alt="" />
            </div>
            <span class="small-caption">Photo Taken in 2017 in Lesego's Bedroom</span>
          </div>
          <div class="early-versions-2">
            <span class="small-caption">Early Version Matric Live</span>
            <div class="early-versions-2_1">
              <img class="slide-in" src="@/assets/aboutpage/be2.svg" alt="" />
              <img class="slide-in" src="@/assets/aboutpage/be1.svg" alt="" />
            </div>
          </div>
        </div>
        <p class="reveal">
          <b>Reid Hoffman's</b> quote — “If you are not embarrassed by the first
          version of your product, you've launched too late.”
        </p>
      </div>
    </div>
    <div class="awards">
      <span class="head">Awards</span>
      <p>
        <b>Matric Live</b> has is a multiple award winning application and has
        won some awards that continue validate the impact it has on learners.
      </p>
      <div class="awards_1">
        <div class="awards_1_1">
          <div class="fadein-img">
            <img src="@/assets/aboutpage/unnamed 1.svg" alt="" />
          </div>
          <span class="bold-small-text">2019 Best Educational Solution</span>
        </div>
        <div class="awards_1_2">
          <div class="fadein-img">
            <img src="@/assets/aboutpage/lead sa.svg" alt="" />
          </div>
          <span class="bold-small-text">2017 Lead SA Youth Hero</span>
        </div>
        <div class="awards_1_3">
          <div class="fadein-img">
            <img src="@/assets/aboutpage/app-mtn 1.svg" alt="" height="258" />
          </div>
          <span class="bold-small-text">2020 Breakthrough Solution</span>
        </div>
      </div>
    </div>
    <hr class="line" />
    <div class="other-products-section">
      <span class="head">Other Products</span>
      <div class="products1">
        <div class="products_1_1">
          <div>
            <img src="@/assets/aboutpage/e-Exam.svg" alt="" height="458" />
          </div>
          <span class="small-bold-text">Department of Education eAssessment</span>
        </div>
        <div class="products_1_2">
          <div>
            <img src="@/assets/aboutpage/Group 13 1.svg" alt="" />
          </div>
          <span class="small-bold-text">New Features coming to Matric Live 26.0</span>
        </div>
        <div class="products_1_3">
          <div>
            <img src="@/assets/aboutpage/assessr.svg" alt="" />
          </div>
          <!-- <span class="small-bold-text"
            >AssessR</span
          > -->
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ScrollReveal from "scrollreveal";
export default {
  name: "OurStory",
  mounted() {
    const sliders = document.querySelectorAll(".slide-in");

    const appearOptions = {
      threshold: 0,
      rootMargin: "0px 0px -250px 0px",
    };

    const appearOnScroll = new IntersectionObserver(function (
      entries,
      appearOnScroll
    ) {
      entries.forEach((entry) => {
        console.log(entry);
        if (!entry.isIntersecting) {
          return;
        } else {
          entry.target.classList.add("appear");
          appearOnScroll.unobserve(entry.target);
        }
      });
    },
      appearOptions);

    sliders.forEach((slider) => {
      appearOnScroll.observe(slider);
    });

    // ScrollReveal().reveal(".reveal", { delay: 1000 });

    // ScrollReveal().destroy();
    if (screen.width > 950) {
      var scale = document.getElementById("fade");
      setTimeout(() => {
        scale.classList.add("scale-down");
      }, 500);

      var finalWidth;
      setTimeout(() => {
        finalWidth = scale.getBoundingClientRect().width + "px";
        scale.style.width = finalWidth;
        // scale.scrollTop = scale.scrollHeight;
        // document.getElementById("story-id").scrollIntoView();
      }, 2500);
      scale.style.width = finalWidth;

    }

    var slideFromRight = {
      distance: "200%",
      origin: "right",
      duration: 2000,
      opacity: null,
    };

    //ScrollReveal().reveal(".p-content", { interval: 400});
    ScrollReveal().reveal(".fadein-img", { interval: 400, duration: 10 });
    ScrollReveal().reveal(".early-versions-2_1", slideFromRight);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
main {
  background-image: url("../assets/careerspage/Desktop - 1.svg");
  background-size: auto;
  overflow: hidden;
  /* overflow-x: hidden;
  scroll-behavior: smooth; */
}

.first-body-section {
  margin: 28px 0px;
  /* padding: 5%; */
}

.first-body-section img {
  /* margin-left: 10px; */
  margin-left: auto;
  margin-left: auto;
}

.responsive-fade {
  display: none;
}

.early-versions {
  display: flex;
  margin: 4% 0px;
  justify-content: space-around;
}

.early-versions-1_1 {
  margin: 15px;
}

.awards_1 {
  overflow: scroll;
  scroll-behavior: smooth;
}

.awards_1::-webkit-scrollbar {
  display: none;
}

.rect-placeholder {
  text-align: start;
  margin: 8%;
}

.title {
  font-weight: 800;
  font-size: xxx-large;
}

.our-story-section {
  text-align: center;
  margin: 3% 0px;
}

div>.small-caption {
  font-size: small;
  color: gray;
}

.fade-in {

  width: 498px;
  height: 444px;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

/* .scale-down {
  transform-origin: top;
  animation: scale 2000ms ease-in-out backwards;
} */

@keyframes scale {
  to {
    transform: scale(0.6);
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

hr {
  border-top: 2px dashed mediumpurple;
  width: 33%;
  margin: 5% auto;
}

.from-bottom {
  grid-column: 2 / 3;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.from-bottom {
  transition: opacity 250ms ease-in, -webkit-transform 1s ease-in;
  transition: opacity 250ms ease-in, transform 1s ease-in;
  transition: opacity 250ms ease-in, transform 1s ease-in,
    -webkit-transform 1s ease-in;
  opacity: 0;
}

.from-bottom.appear {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.from-left {
  grid-column: 2 / 3;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.from-left {
  transition: opacity 250ms ease-in, -webkit-transform 1s ease-in;
  transition: opacity 250ms ease-in, transform 1s ease-in;
  transition: opacity 250ms ease-in, transform 1s ease-in,
    -webkit-transform 1s ease-in;
  opacity: 0;
}

.from-left.appear {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

.awards {
  text-align: initial;
  margin: 8%;
  justify-content: space-around;
}

.other-products-section .head,
.awards .head {
  font-weight: 800;
  font-size: larger;
}

.awards>p {
  margin: 2% 0px;
}

.awards .awards_1 {
  display: flex;
  justify-content: space-between;
}

.other-products-section .small-bold-text,
.bold-small-text {
  font-weight: 700;
  font-size: smaller;
  margin: 5px 0px;
}

.products_1_1,
.products_1_2,
.products_1_3 {
  text-align: center;
}

.products_1_1>div,
.products_1_2>div,
.products_1_3>div,
.awards_1_1>div,
.awards_1_2>div,
.awards_1_3>div {
  margin: 18px 0px;
}

.products1 {
  display: flex;
  margin: 2% 0px;
  overflow: scroll;
  justify-content: space-between;
}

.products1::-webkit-scrollbar {
  display: none;
}

.other-products-section {
  text-align: initial;
  margin: 8% 8% 16% 8%;
}

@media screen and (max-width: 950px) {
  .responsive-fade {
    display: block;
    width: 350px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  #fade {
    display: none;
  }

  .products1 {
    display: block;
  }

  .awards .awards_1 {
    display: block;
  }

  .early-versions {
    display: block;
  }

  .early-versions-1_1 img {
    width: 300px;
    height: 150px;
  }

  .other-products-section .products1 .products_1_1 div img,
  .other-products-section .products1 .products_1_2 div img,
  .awards_1 .awards_1_1 img,
  .awards_1 .awards_1_2 img,
  .awards_1 .awards_1_3 img {
    width: 300px;
    height: 150px;
  }

  .early-versions-2 .early-versions-2_1 img {
    width: 150px;
    height: 300px;
  }
}
</style>
